<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Whatsapp Integrations</h3>
      </div>
      <el-row :gutter="12" v-if="waAccounts.length">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container"
        >
          <br />
          <div>
            <el-button type="primary"  @click="newIntegration" style="float:right;margin:1rem;"
              >Add</el-button
            >
          </div>
          <br />
          <template>
            <el-table border style="margin: auto; width: 95%" :data="waAccounts">
              <el-table-column prop="name" label="Account Name" />
              <el-table-column prop="contactNo" label="WA AC Number" />
              <el-table-column prop="type" label="Type" />
            </el-table>
            <center>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="paginationProps"
                :page-size="pageSize"
                :pager-count="5"
                layout="prev, pager, next, jumper,sizes, total"
                small
              >
              </el-pagination>
              <div style="margin-top: 10px">Total</div>
            </center>
          </template>
        </el-col>
      </el-row>
      <el-row :gutter="12" v-else>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-20 ml-3 mb-10"
        >
          <div class="no-data">
            <img src="@/assets/img/nodata2.svg" alt="No Data" />
            <p>
              There are no integrations currently available. Click 'Add New
              Integration' to set up an integration.
            </p>
            <div>
              <el-button type="primary" @click="newIntegration"
                >Add New Integration</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "AddNewIntegration",
  data() {
    return {
      loading: false,
      loadingText:'Loading .....',
      waAccounts:[],
    };
  },
  async mounted()
  {
    this.getWAAccounts();
  },
  methods: {
    newIntegration() {
      this.$router.push({ path: "/configuration/Whatsapp" });
    },

    async getWAAccounts()
    {
      this.loading = true;
      this.loadingText = 'Loading Whatsapp Accounts ...';
      let response= await postAPICall("GET","/wa/get-WA-integration-data");

      if(response && response.success)
      {
        this.waAccounts = response.data;
        this.loading=false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
</style>
